<template>
  <v-dialog v-model="dialogOrder" persistent max-width="1200px">
    <v-card class="pa-0" elevation="2">
      <v-card-text class="pb-0">
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-container fluid>
            <!-- Entry Info section -->
            <v-row>
              <v-col cols="12" class="pa-0">
                <span class="display-2">
                  <v-icon left class="pb-1">mdi-apple-finder</v-icon>
                  {{ $vuetify.lang.t("$vuetify.SelectOrder") }}
                </span>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="datas"
                  :search="search"
                  :single-select="true"
                  :items-per-page="10"
                  :single-expand="true"
                  :expanded.sync="expanded"
                  show-select
                  show-expand
                  mobile-breakpoint="960"
                  class="elevation-2"
                  @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
                >
                  <template v-slot:top>
                    <v-text-field
                      v-model="search"
                      label="Search"
                      outlined
                      class="px-6 pt-6 pb-0"
                      prepend-inner-icon="mdi-magnify"
                    />
                  </template>

                  <template v-slot:[`item.status`]="{ item }">
                    <label v-if="item.status == '-1'">
                      {{ $vuetify.lang.t("$vuetify.cancel") }}
                    </label>

                    <label v-if="item.status == '1'">
                      {{ $vuetify.lang.t("$vuetify.placeOrder") }}
                    </label>

                    <label v-if="item.status == '3'">
                      {{ $vuetify.lang.t("$vuetify.paid") }}
                    </label>

                    <label v-if="item.status == '7'">
                      {{ $vuetify.lang.t("$vuetify.offStocks") }}
                    </label>
                    <label v-if="item.status == '6'">
                      {{ $vuetify.lang.t("$vuetify.shipped") }}
                    </label>
                    <label v-if="item.status == '2'">
                      {{ $vuetify.lang.t("$vuetify.daichengtuan") }}
                    </label>
                  </template>

                  <template v-slot:[`item.price`]="{ item }">
                    <v-chip color="primary" class="font-weight-bold">
                      $ {{ item.price }}
                    </v-chip>
                  </template>

                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="py-8 px-6">
                      <v-data-table
                        style="border: 0px solid black"
                        class="elevation-4"
                        :items="item.products"
                        :headers="expanded_headers"
                      />
                    </td>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="pb-8 pt-4">
        <v-spacer />

        <v-btn color="red darken-2" outlined @click="cancelForm">
          {{ $vuetify.lang.t("$vuetify.cancel") }}
        </v-btn>

        <v-btn color="green darken-2" @click="handleSelectedData">
          {{ $vuetify.lang.t("$vuetify.save") }}
        </v-btn>

        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { orderList } from "@/api/order";

const newSearchBack = () => {
  return {
    skip: 0,
    limit: "1",
    key: "",
  };
};

export default {
  name: "SelectOrder",
  props: { dialogOrder: Boolean },
  data() {
    return {
      search: "",
      selected: [],
      expanded: [],
      valid: false,
      radios: null,
      datas: [],
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.orderNo"),
          value: "po",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.cutomerInfo"),
          value: "customer_info.contact",
          width: "150px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.number"),
          value: "number",
          width: "130",
          sortable: false,
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.price"),
          value: "price",
          width: "140",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.status"),
          value: "status",
          width: "140px",
          sortable: false,
        },
      ],
      expanded_headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.commodity"),
          value: "nick_name",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.orderQty"),
          value: "number",
          sortable: false,
          width: "140",
          align: "center",
        },
        // {
        //   text: this.$vuetify.lang.t("$vuetify.NumberOfFactories"),
        //   value: "factory_num"
        // },
      ],
      searchBackForm: newSearchBack(),
    };
  },
  methods: {
    cancelForm() {
      this.$emit("update:dialogOrder", false);
    },
    reset() {
      this.$refs.form.reset();
    },
    handleSelectedData() {
      if (this.selected.length) {
        this.$emit("getselectedOrder", this.selected);
        this.$emit("update:dialogOrder", false);
      } else {
        this.$toast.error("Please select an order");
      }
    },
    getData() {
      orderList(this.searchBackForm)
        .then((data) => {
          orderList({ skip: 0, limit: data.total, key: "" })
            .then((res) => {
              if (res.items != null) {
                this.datas = [...res.items];
              }
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="sass">
.v-data-table__expanded.v-data-table__expanded__content
  box-shadow: none !important
</style>