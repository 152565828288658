var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"1200px"},model:{value:(_vm.dialogOrder),callback:function ($$v) {_vm.dialogOrder=$$v},expression:"dialogOrder"}},[_c('v-card',{staticClass:"pa-0",attrs:{"elevation":"2"}},[_c('v-card-text',{staticClass:"pb-0"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"display-2"},[_c('v-icon',{staticClass:"pb-1",attrs:{"left":""}},[_vm._v("mdi-apple-finder")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.SelectOrder"))+" ")],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.datas,"search":_vm.search,"single-select":true,"items-per-page":10,"single-expand":true,"expanded":_vm.expanded,"show-select":"","show-expand":"","mobile-breakpoint":"960"},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"px-6 pt-6 pb-0",attrs:{"label":"Search","outlined":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == '-1')?_c('label',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.cancel"))+" ")]):_vm._e(),(item.status == '1')?_c('label',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.placeOrder"))+" ")]):_vm._e(),(item.status == '3')?_c('label',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.paid"))+" ")]):_vm._e(),(item.status == '7')?_c('label',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.offStocks"))+" ")]):_vm._e(),(item.status == '6')?_c('label',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.shipped"))+" ")]):_vm._e(),(item.status == '2')?_c('label',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.daichengtuan"))+" ")]):_vm._e()]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"primary"}},[_vm._v(" $ "+_vm._s(item.price)+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-8 px-6",attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-4",staticStyle:{"border":"0px solid black"},attrs:{"items":item.products,"headers":_vm.expanded_headers}})],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pb-8 pt-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-2","outlined":""},on:{"click":_vm.cancelForm}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"green darken-2"},on:{"click":_vm.handleSelectedData}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.save"))+" ")]),(_vm.$vuetify.breakpoint.xsOnly)?_c('v-spacer'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }